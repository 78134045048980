import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ELMAH_APIKEY, PUBLIC_ELMAH_LOGID } from '$env/static/public';
import Elmahio from 'elmah.io.javascript';

// If you don't want to use Session Replay, remove the `Replay` integration, 
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
    dsn: "https://a9001f9bbce0b22ad2fdf2dbd33246d3@o4507250767167488.ingest.de.sentry.io/4507250769526864",
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [Sentry.replayIntegration()]
})

var logger = new Elmahio({
				apiKey: PUBLIC_ELMAH_APIKEY,
				logId: PUBLIC_ELMAH_LOGID
});

export const handleError = Sentry.handleErrorWithSentry(function _handleError({ error, event, status, message }) {
				if (error && error.message) {
								logger.error(error.message, error);
				} else {
								logger.error('Error in application', error);
				}
});